import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import I18n from 'common/i18n';
import { ForgePageState } from '@tylertech/forge-react';

const t = (k: string, scope = 'dataset_landing_page.dataset_preview.widget_mode.errors') =>
  I18n.t(k, { scope });

const embedErrorContentByCode: Record<number, { image: string; title: string; text: string }> = {
  403: {
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/403-error-spot-hero.svg',
    title: t('forbidden.title'),
    text: t('forbidden.text')
  },
  404: {
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/404-error-spot-hero.svg',
    title: t('not_found.title'),
    text: t('not_found.text')
  },
  500: {
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/500-error-2-spot-hero.svg',
    title: t('internal_error.title'),
    text: t('internal_error.text')
  },
  400: {
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/general-error-spot-hero.svg',
    title: t('bad_request.title'),
    text: t('bad_request.text')
  },
  0: {
    image: 'https://cdn.forge.tylertech.com/v1/images/spot-hero/general-error-spot-hero.svg',
    title: t('generic_error.title'),
    text: t('generic_error.text')
  }
};

export const DataPreviewWidgetError: React.FunctionComponent = () => {
  const shadowDomRef = useRef<HTMLDivElement>(null);
  // we check if we handle the given error code, if not, default to 0 (generic error)
  const errorCode = window.coreErrorStatusCode! in embedErrorContentByCode ? window.coreErrorStatusCode! : 0;
  const { image, title, text } = embedErrorContentByCode[errorCode];

  return (
    <div className="data-tab-widget-error-frame">
      <ForgePageState id="data-tab-widget-error-state" ref={shadowDomRef}>
        <img slot="graphic" src={image} alt="" />
        <div slot="title">{title}</div>
        <p slot="message">{text}</p>
      </ForgePageState>
    </div>
  );
};

// Don't trigger this if this is imported in the test file
if (document.querySelector('#data-tab-widget-error')) {
  ReactDOM.render(<DataPreviewWidgetError />, document.querySelector('#data-tab-widget-error'));
}
